@import url("./Styles/font.css");
@import url("./Styles/color.css");

.App {
  text-align: center;
  font-family: "Averia Serif Libre", serif;
}

html,
body {
  height: 100%;
  margin:0 24px 0 24px;
  padding: 0;
  background-color: var(--brown-010);
  font-family: "Averia Serif Libre", serif !important;
}
p{
  font-size: 14px;
  font-family: "Averia Serif Libre", serif !important;
  font-weight: 200;
  margin: 0;
}
.container {
  display: flex;
  flex-direction: column;
  width: 640px;
  align-items: center;
  justify-content: start;
  text-align: center;
  gap:24px;

}

.interactionArea {
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  height: 400px;
}


.textButton {

  background-color: transparent;
  font-family: "Averia Serif Libre", serif;
  font-size: 20px;
  color: var(--primary-color);
  border: none;
  padding: 8px 12px 8px 12px;
  cursor: pointer;


}

.textButton:hover {
  background-color: var(--brown-030);
  color: var(--primary-color);
  border: none;
  border-radius: 8px;

}

.button-iconOnly {
  display: flex;
  direction: row;
align-items: center;
  background-color: var(--brown-030);
  font-family: "Averia Serif Libre", serif;
  font-size: 20px;
  padding-left: 24px;
  padding-right: 24px;
  height: 64px;
  border-radius: 32px;
  color: var(--primary-color);
  box-shadow: none;
  border: none;
  transition: background-color 0.3s ease;
  gap:16px;

}

.back-button {

  background-color: var(--brown-030);
  font-family: "Averia Serif Libre", serif;
  font-size: 20px;
  width: 112px;
  height: 64px;
  border-radius: 16px;
  color: var(--primary-color);
  box-shadow: none;
  border: none;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: var(--primary-color);
  /* Darken primary button color on hover */
  color: white;
  cursor: pointer;
}

.button-iconOnly:disabled {
  background-color: var(--brown-010);

}

.button-iconOnly:hover {
  background-color: var(--primary-color);
  /* Darken primary button color on hover */
  color: white;
  cursor: pointer;
}

.moveSteps {
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* Aligns items to each end of the container */
}

.textArea {
  position: relative;
  border: none;
  background-color: transparent;
  resize: none;
  font-size: 24px;
  font-family: "Averia Serif Libre", serif;
  width: 100%;
  height: 400px;
  caret-color: var(--primary-color);
  /* Hide the default cursor */
  outline: none;
  text-align: center;
  vertical-align: center;
  max-width: 480px;
}

.distortedGroup{

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap:16px;
  flex-wrap: wrap;
}


.optionBox {
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items: start;
  height: 120px;
  background-color: var(--brown-020);
  border-radius: 8px;
  font-family: "Averia Serif Libre", serif;
  font-size: 18px;
  font-weight: 500;
  padding: 16px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;
  border: 1px solid var(--brown-030);
  flex:1;
  min-width: 200px;


}

.optionBox:hover {

  background-color: var(--brown-030);


}

.optionBox.selected {
  background-color: var(--primary-color);
  color: white;
}

.optionBox.selected:hover {
  background-color: var(--brown-080);
}

.optionBoxText{
  display: flex;
  flex-direction: column;
  gap:4px;
}

.footer {
  display: flex;        
  width: 100%;
  position: relative;
  align-items: center;   
  justify-content: center; 
  height: 64px;
}

.prevButton {
  position: absolute;
  left: 0;
}

.nextButton {
  position: absolute;
  right: 0;
}


@keyframes shimmer {
  0% {
    background-position: -40rem 0;
  }
  100% {
    background-position: 40rem 0;
  }
}

.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, var(--brown-020), var(--brown-030), var(--brown-020) 33%);
  background-size: 80rem 100%;
}

.summaryBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--brown-020);
  border-radius: 8px;
  font-family: "Averia Serif Libre", serif;
  padding: 16px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;
  border: 1px solid var(--brown-030);
  
}

.summaryImg{
  width: 100%;
  border-radius: 8px;
}